import { AsyncPipe, isPlatformBrowser } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import {
  CUSTOM_ELEMENTS_SCHEMA,
  ChangeDetectionStrategy,
  Component,
  NgZone,
  OnInit,
  PLATFORM_ID,
  computed,
  inject,
} from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatIconModule, MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { Router, RouterOutlet } from '@angular/router';
import { environment } from '@environment';
import { FeaturesRoutingEnum } from '@features/features-routing.enum';
import { RoleTypes } from '@models/roles';
import { Store } from '@ngrx/store';
import { selectRouteParams, selectUrl } from '@stores/router/router.selectors';
import {
  BoSideMenuModule,
  NavigationItem,
  PageUnauthorizedModule,
  WzbBoNavigationSidebarMenuComponent,
  WzbBoNotificationsComponent,
  wzbBoNavigationSidebarMenuIconsDirective,
} from '@wizbii-components/bo-angular-ui';
import { isLogged, roles } from '@wizbii-utils/angular/stores';
import { WebVitalsOptions, WebVitalsParams, sendWebVitals } from '@wizbii/utils';
import { Observable, combineLatest } from 'rxjs';
import { filter, map, switchMap, take } from 'rxjs/operators';

@Component({
  selector: 'app-core',
  standalone: true,
  imports: [
    AsyncPipe,
    RouterOutlet,
    HttpClientModule,
    PageUnauthorizedModule,
    WzbBoNavigationSidebarMenuComponent,
    MatIconModule,
    WzbBoNotificationsComponent,
    ReactiveFormsModule,
    wzbBoNavigationSidebarMenuIconsDirective,
    BoSideMenuModule,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  templateUrl: './core.component.html',
  styleUrls: ['./core.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CoreComponent implements OnInit {
  readonly #ngZone = inject(NgZone);
  readonly matIconRegistry = inject(MatIconRegistry);
  readonly domSanitizer = inject(DomSanitizer);
  readonly #store = inject(Store);
  readonly #platformId = inject(PLATFORM_ID);
  readonly #router = inject(Router);

  activeRoute = computed<string | string[]>(
    () => `/${this.#store.selectSignal(selectUrl)()?.split('/')[1]?.split('?')[0]}`
  );

  readonly items: NavigationItem[] = [
    {
      route: `/${FeaturesRoutingEnum.Nme}`,
      label: 'Dossiers',
      icon: 'money',
      grantedTo: [RoleTypes.ROLE_HELPII_MANAGER, RoleTypes.ROLE_HELPII],
    },
    {
      route: `/${FeaturesRoutingEnum.DossierHotline}`,
      label: 'Dossiers Hotline',
      icon: 'folder',
      grantedTo: [RoleTypes.ROLE_HELPII_MANAGER, RoleTypes.ROLE_HELPII],
    },
    {
      route: `/${FeaturesRoutingEnum.SwanMigration}`,
      label: 'Swan Migration',
      icon: 'files-landscape',
      grantedTo: [RoleTypes.ROLE_HELPII_MANAGER, RoleTypes.ROLE_HELPII],
    },
    {
      route: `/${FeaturesRoutingEnum.BankingFlowRepartition}`,
      label: 'Repartition du flux',
      icon: 'user-debug',
      grantedTo: RoleTypes.ROLE_BANKING_FLOW_CONFIGURATOR,
    },
  ];

  readonly env = environment.platform;
  get icons(): (string | undefined)[] {
    return [...this.items].map((i) => i.icon);
  }

  get grantedRoutes$(): Observable<NavigationItem[]> {
    return this.#store.select(isLogged).pipe(
      filter((isInitialized: boolean) => isInitialized),
      take(1),
      switchMap(() => this.#store.select(roles)),
      map((userRoles) => {
        return this.items.filter((item) => {
          if (!item.grantedTo) {
            return true;
          }

          return typeof item.grantedTo === 'string'
            ? userRoles?.includes(item.grantedTo)
            : userRoles?.some((role) => (item.grantedTo ?? [])?.includes(role));
        });
      })
    );
  }

  constructor() {
    this.#ngZone.runOutsideAngular(() => {
      this.matIconRegistry.addSvgIconSet(
        this.domSanitizer.bypassSecurityTrustResourceUrl(`${environment.deployAssetsUrl}/sprite.svg`)
      );
    });
  }

  ngOnInit(): void {
    combineLatest([this.#store.select(selectUrl), this.#store.select(selectRouteParams)])
      .pipe(
        filter(([url, routeParams]) => !!url && !!routeParams),
        take(1)
      )
      .subscribe(([url, routeParams]) => {
        if (url) {
          const params: WebVitalsParams = {
            params: routeParams,
            path: url,
            applicationId: environment.applicationId,
            envFqdn: environment.domain.wizbii,
          };
          const options: WebVitalsOptions = {
            dev: environment.platform === 'local',
            debug: environment.platform === 'local',
            browser: isPlatformBrowser(this.#platformId),
          };
          sendWebVitals(params, options);
        }
      });
  }

  handleItemClicked(item: NavigationItem): void {
    this.#router.navigate([item.route]);
  }

  trackByIcon(index: number, icon?: string): string {
    return `${index}-${icon}`;
  }
}
