/* eslint-disable @typescript-eslint/no-var-requires */

/* eslint-disable no-restricted-imports */
import { buildConfig } from './build-config';
import { getBuildEnvVar, getRuntimeEnvVar } from '@wizbii-utils/angular/core';
import { LangEnum, LocaleEnum } from '@wizbii/utils/models';

const packageJsonVersionAtBuild = require('../../package.json').version;
// eslint-disable-next-line @typescript-eslint/ban-ts-comment, @typescript-eslint/no-unnecessary-condition
const production: boolean = (buildConfig as any) === 'production';
const applicationId = 'helpii';

const lang: keyof typeof LangEnum = getBuildEnvVar('LANG');
const locale: keyof typeof LocaleEnum = getBuildEnvVar('LOCALE');

const platform = getRuntimeEnvVar('PLATFORM');
const wizbiiDomain = getRuntimeEnvVar('WIZBII_ENV_FQDN');
const wizbiiTeamDomain = getRuntimeEnvVar('WIZBII_TEAM_ENV_FQDN');
const apiDomain = getRuntimeEnvVar('API_DOMAIN');
const scheme = getRuntimeEnvVar('HTTP_SCHEME');

const deployAssetsUrl = production
  ? `https://storage.googleapis.com/wizbii-application-assets/helpii/${getBuildEnvVar('CI_COMMIT_SHA')}/fr/assets`
  : '/assets';

const helpiiUrl = platform !== 'local' ? window.location.origin : 'http://localhost:4200';

export const environment = {
  applicationId,
  platform,
  production,
  version: production ? packageJsonVersionAtBuild : getBuildEnvVar('GIT_BRANCH_NAME', 'next'),
  i18n: {
    lang: LangEnum[lang],
    locale: LocaleEnum[locale],
  },
  domain: {
    wizbii: wizbiiDomain,
    wizbiiTeam: wizbiiTeamDomain,
    cookieDomain: window.location.hostname === 'localhost' ? 'localhost' : wizbiiTeamDomain,
  },
  bugsnag: {
    key: '56b5f54b0bf47bb8c367a3fb48ca3a36',
  },
  api: {
    account: `${scheme}://account.api.${apiDomain}`,
    domain: apiDomain,
    authentication: `${scheme}://auth.${apiDomain}`,
    fibii: `${scheme}://fibii.api.${apiDomain}`,
    file: `${scheme}://file.${apiDomain}`,
    suggestion: `${scheme}://suggestion.api.${apiDomain}`,
    mercure: `${scheme}://mercure.${apiDomain}`,
    loyaltyPartner: `${scheme}://loyalty-p-api.${apiDomain}`,
    collectivity: `${scheme}://collectivite.api.${apiDomain}`,
    place: `${scheme}://place.${apiDomain}`,
    bankingProcessor: `${scheme}://banking-processor.api.${apiDomain}`,
  },
  urls: {
    sso: `${scheme}://sso.internal.${apiDomain}?redirect_url=${helpiiUrl}&appId=${applicationId}`,
    googleStorage: 'https://storage.googleapis.com',
    mangoPay: {
      dashboard: platform === 'prod' ? 'https://dashboard.mangopay.com' : 'https://dashboard.sandbox.mangopay.com',
    },
    swan: {
      dashboard:
        platform === 'prod'
          ? 'https://dashboard.swan.io/projects/f4f0be2a-6eee-45d8-a96d-60558fc0f94f/live/data'
          : 'https://dashboard.swan.io/projects/dffc6e71-ce13-4d7a-a9cd-d913354cd137/sandbox/data',
    },
    suggestii: `${scheme}://suggestii.${wizbiiTeamDomain}`,
  },
  algolia: {
    index: {
      client: `${platform}_helpii_client`,
      dossier: `${platform}_helpii_dossier`,
    },
  },
  wizbiiFiles: getRuntimeEnvVar('WIZBII_FILES_GCS_BUCKET', 'wizbii-files-qa3'),
  suggestionSetId: getRuntimeEnvVar('SUGGESTION_SET_ID', 'all-in-one'),
  deployAssetsUrl,
};
